.bubbleWrapper,
.textSectionContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verticalLine {
  background-color: #f5faff;
  width: 2px !important;
  height: 80%;
}

.panel {
  height: 500px;
}

.customBorder {
  border-left: 0.5px;
  height: 450px;
}

.textSection {
  font-size: 16px;
}

.textSectionContainer {
  max-width: 600px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}




