.container {
  background-color: #f5faff;
  border: none;
  cursor: pointer;
  overflow: visible;
  outline: none;
  position: relative;
  border-radius: 10px; /* Small border radius for rounded rectangle */
  padding: 10px; /* Add some padding */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.3s ease-in-out; /* Transition for hover effect */
  font-size: 14px; /* Default font-size relative to viewport width */
}

.container:hover {
  transform: scale(1.05); /* Minimal hover effect */
}

/* Small devices (phones, less than 600px) */
@media only screen and (max-width: 600px) {
  .container {
    font-size: 11px !important; /* Adjust font-size relative to viewport width for mobile */
  }
}
