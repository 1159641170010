.h1 {
    font-family: "Inter", sans-serif;
}

.h3 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.p {
    font-family: "Inter", sans-serif;
    font-weight: 300;
}

