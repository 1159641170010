.btn {
    cursor: pointer;
    outline: 0;
    color: #fff;
    background-color: #A9CAD2;
    border-color: #A9CAD2;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn:hover {
    color: #fff;
    background-color: #7FA6AC;
    border-color: #7FA6AC;
}

.prominent {
    background-color: #769FA7 !important;
    border-color: #769FA7 !important;
}

.prominent:hover {
    background-color: #63878A !important;
    border-color: #63878A !important;
}
