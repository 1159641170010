.profilePic {
  width: 350px; /* Adjust size as needed */
  height: 350px;
  border-radius: 10%; /* Slight border rounding */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); /* Shadow effect */
  transition: transform 0.3s ease-in-out; /* Transition for hover effect */
  margin-left: 130px;
  margin-right: 100px;
}

/* Small devices (phones, less than 600px) */
@media only screen and (max-width: 600px) {
  .profilePic {
    /* Scale down image size for small devices */
    width: 200px;
    height: 200px;
    /* Smaller margins for mobile devices */
    margin-left: 30px;
    margin-right: 30px;
  }
}


.profilePic:hover {
  transform: scale(1.05); /* Minimal hover effect */
}

.verticalLine {
  border-left: 2px solid #a9cad2; /* Darker variant of navbar color */
  height: 80%; /* Adjust height as needed */
  margin: 0 10px; /* Space around the line */
}

.textSection {
  margin-right: 100px; /* Adds space to the left of the text section */
  margin-left: 100px;
}

/* Small devices (phones, less than 600px) */
@media only screen and (max-width: 600px) {
  .textSection {
    /* Smaller margins for mobile devices */
    margin-right: 30px;
    margin-left: 30px;
  }
}

.textSection h1 {
  font-family: "Inter", sans-serif;
}

.textSection h3 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.btnContainer {
  margin-top: 20px;
  margin-left: 65px;
}

.iconWithText {
  display: flex;
  align-items: center;
}

.iconWithText svg {
  margin-right: 8px; /* Adjust as needed */
}

.paragraph {
  text-align: left;
  /* Additional styles as needed */
}

.githubIcon {
  font-size: 2.5em;
  display: block;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out; /* Transition for hover effect */
}

.githubIcon:hover {
  transform: scale(1.3); /* Minimal hover effect */
}

.githubLink {
  opacity: 1; /* Reset opacity */
  color: inherit; /* Make sure the link color doesn't affect the icon */
  text-decoration: none; /* Removes underline */
}

.keywords {
    text-align: center;
}
