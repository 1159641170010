* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

li, a {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #edf0f1;
    text-decoration: none;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
    background-color: #CCE0E5;
}

.navList {
    list-style: none;
}

.navList li {
    display: inline-block;
    padding: 0px 20px;
}

.navList li a {
    transition: all 0.3s ease 0s;
}

.menuItems {
    @apply border-transparent bg-custom-blue hover:bg-custom-blue-hover mt-2 self-center transition ease-in-out w-4/5 text-white font-medium py-2 rounded-sm;
}

