body {
  zoom: 1.5; /* Zoom level for medium devices */
}


.global {
  background-color: #F0FFFF;
  min-height: 100vh;
}

.container {
  width: 100%;
  margin: 0 auto;
}

/* This will apply when the screen width is below 600px
@media screen and (max-width: 600px) {
  .container {
    width: 95%;
  }
} */
/* #d0e8fd; */