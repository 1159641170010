.profilePic {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); /* Shadow effect */
}


  .customBorder {
    border-left: 0.1px;
  }

  .textSectionMargin {
    margin-right: 150px;  /* Adds space to the left of the text section */
  }

  .textSection h1 {
    font-family: 'Inter', sans-serif;
  }

  .textSection h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }

  .typewriter {
    overflow: hidden;
    border-right: .15em solid rgb(0, 0, 0);
    white-space: nowrap;
    margin: 0 auto;
    animation: 
      typing 1s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(0, 0, 0); }
  }